import { Amplify, Auth } from "aws-amplify";

Amplify.configure({
  Auth: {
    region: "ap-northeast-1",
    userPoolId: "ap-northeast-1_tomRJNswB",
    userPoolWebClientId: "5a1pl6ufcdfs5tjek6ftck8f6",
    oauth: {
	    domain: "auth.dx0-spa.dxt.cloud.ntc-s.co.jp",
      redirectSignIn: "https://dx0-spa-example.dxt.cloud.ntc-s.co.jp/",
      redirectSignOut:  "https://dx0-spa-example.dxt.cloud.ntc-s.co.jp/",
      responseType: "code",
    }
  }
})
 
export const signIn = async () => {
  Auth.federatedSignIn();
}
 
export const signOut = async () => {
  Auth.signOut();
}
 
export const getUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (e) {
    return console.log("サインインしていません");
  }
}
