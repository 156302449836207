import { Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import { signIn, signOut, getUser } from "./Auth";
import Api from "./Api.js";
import { MDBNavbar, MDBContainer, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
 
function App() {
    const [user, setUser] = useState(null);
  
    useEffect(() => {
      Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) { 
          case 'signIn':
          case 'cognitoHostedUI':
            getUser().then(userData => setUser(userData));
            break;
          case 'signOut':
            setUser(null);
            break;
          case 'signIn_failure':
          case 'cognitoHostedUI_failure':
            console.log('Sign in failure', data);
            break;
          default: break;
        }
      });
      getUser().then(userData => setUser(userData));
    }, []);
    
    var username = '未認証ユーザー';
    var loginflag = false;
    if(user){
      username = user.username;
      loginflag = true;
    }
    
    return (
      <div>
        <MDBNavbar bgColor='#eef' >
         <MDBContainer fluid >
          <div className="d-flex flex-row justify-content-start">
            <MDBIcon far icon="user" size='2x' />
            <p className="ms-2">{username}</p>
          </div>
          <div className='justify-content-md-end'>
            <MDBBtn onClick={() => signIn()} disabled={loginflag}>Sign In</MDBBtn>
            <MDBBtn onClick={() => signOut()} disabled={!loginflag} className="ms-2">Sign Out</MDBBtn>
          </div>
         </MDBContainer>
        </MDBNavbar>
        { user ? (
            <div>
                <Api user={user}/>
            </div>
        ) : (
          <p>サインインしてください</p>
        )}
      </div>
    );
  }
 
export default App;