import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  MDBCard,
  MDBCardHeader,
  MDBBtn,
  MDBCardFooter,
  MDBInputGroup
} from "mdb-react-ui-kit";
import Talk from "./Talk.js";

const Api = ({user}) => {
    const [talk, setTalk] = useState([]);
    const [message, setMessage] = useState('');
    const apiKey = 'UNB0AEjpNF9rYjSp1MGsf2ImXN4EOzkM1CMvVZWe';
    const url = 'https://6az1a5722b.execute-api.ap-northeast-1.amazonaws.com/dx0-spa-example-stage';
    const username = user.username;
    const idToken = user.signInUserSession.idToken.jwtToken;
    
    
    const Load =  async () => {
      axios.get(url, { headers: {
            "Authorization": idToken,
            'X-Api-Key' : apiKey
            }})
      .then(res => {
        setTalk(res.data);
        console.log("Load");
      }).catch(error => {
          console.log(error);
      })
    }
    
    useEffect(() => {
      Load();
      setInterval(() => {
        Load();
      }, 30000);
      // eslint-disable-next-line
    }, [])
    
    const Send = async () => {
      if (message === '') return;
      await axios.post(url, 
          {user: username, message}, 
          { headers: {
            "Authorization": idToken,
            'X-Api-Key' : apiKey
            }}
      ).then(res => {
        setMessage('');
      }).catch(error => {
          console.log(error);
      })
      Load();
    }
    
    return(
      <MDBCard id="chat2" style={{ borderRadius: "15px" }}>
        <MDBCardHeader className="d-flex justify-content-between align-items-center p-3">
          <h5 className="mb-0">DX ChatRoom</h5>
          <MDBBtn color="primary" size="sm" rippleColor="dark" onClick={Load}>Reload</MDBBtn>
        </MDBCardHeader>
        
        <Talk user={username} talk={talk}/>
      
        <MDBCardFooter className="text-muted d-flex justify-content-start align-items-center p-3">
          <MDBInputGroup className="mb-0">
            <input className="form-control" placeholder="Type message" type="text" value={message} onInput={e => setMessage(e.target.value)} />
            <MDBBtn color="primary" style={{ paddingTop: ".55rem" }} onClick={Send}>
              Send
            </MDBBtn>
          </MDBInputGroup>
        </MDBCardFooter>
      </MDBCard>
    )
}

export default Api;
