import { MDBCardBody, MDBIcon } from "mdb-react-ui-kit";

const Talk = ({user, talk}) => {
    
    return(
      <MDBCardBody　className="scroll">
        {talk.map(item => 
          { if ( item.username === user ) { return(
          <div key={item.username+item.inserttime} className="mb-1">
            <div className="d-flex flex-row justify-content-end pt-1">
              <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary message">{item.message}</p>
            </div>
            <div className="d-flex justify-content-end">
              <p className="small me-3 rounded-3 text-muted d-flex justify-content-end">{item.inserttime}</p>
            </div>
          </div>
          )} else { return (
          <div key={item.username+item.inserttime} className="mb-1">
            <div className="d-flex flex-row justify-content-start">
              <p className="small mb-1 fw-bolder" >{item.username}</p>
            </div>
            <div className="d-flex flex-row justify-content-start">
              <MDBIcon fas className="ms-1" icon="user-circle" size="2x" />
              <div className="ms-3">
                <div className="d-flex flex-row justify-content-start">
                  <p className="small rounded-3 p-2 me-3 mb-1 message" style={{ backgroundColor: "#f5f6f7" }}>{item.message}</p>
                </div>
                <p className="small rounded-3 me-3 text-muted">{item.inserttime}</p>
              </div>
            </div>
          </div>
            )}}
        )}
      </MDBCardBody>
    )
}

export default Talk;